/* * * * * * * * * * * * * * * * * * COLORS * * * * * * * * * * * * * * * * * */
$white: #fff;
$black: #000;
$tig-yellow: #f6db1a;
$tig-blue: #2d499b;
$tig-light-blue: #3c7ff9;
$background-1: #f2f2f2;
$tig-grey: #a4aba7;
$tig-success: #ff3c3c;

$neutral-2: #eae9e7;
$neutral-7: #121212;
$caution-2: #9f0000;

$background-2: #f8f4e8;
$neutral-1: #fbfaf5;
$neutral-6: #272727;
$table-text: #292c2a;

$ticket-used: #f93c3c;
$ticket-valid: #2ad95b;
$tig-invalid: #dc3545;
$no-ticket: #000;
$ticket-past: #f93c3c;
$ticket-future: #ff9900;
$ticket-elderly: #9e6900;
/* * * * * * * * * * * * * * * * * * FONTS * * * * * * * * * * * * * * * * * */
$font-roboto: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-aleo: 'Aleo', 'Georgia', 'Times New Roman', Times, serif;

/* * * * * * * * * * * * * * * * * * BREAKPOINTS * * * * * * * * * * * * * * * * * */

$media-query-xs: 0;
$media-query-sm: 576px;
$media-query-md: 768px;
$media-query-lg: 992px;
$media-query-xl: 1200px;

$max-content-width: 1440px;
$max-content-width-small: 420px;
