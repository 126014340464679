.max-w-100px {
  max-width: 100px;
}

.ml-auto {
  margin-left: auto;
}

.w-10 {
  width: 10%;
}
.w-90 {
  width: 90%;
}

.max-w-250px {
  max-width: 250px;
}

.mr-1 {
  margin-right: 8px;
}
.ml-1 {
  margin-left: 8px;
}
