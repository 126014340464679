/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aleo:wght@300;400;700&display=swap");

@import "assets/styles/vars";
/* Importing Bootstrap SCSS file.*/
@import "~bootstrap/scss/bootstrap";

@import "assets/styles/bootstrap-theme";

@import "~ladda/dist/ladda.min.css";
@import "~ngx-toastr/toastr";

@import "assets/styles/defaults";
@import "assets/styles/forms";
@import "assets/styles/modals";
@import "assets/styles/tables";
@import "assets/styles/pagination";
@import "assets/styles/tabs";
@import "assets/styles/spacing";
